<template>
  <div class="order__wrapper">
    <div class="order__header" :class="{'order__header--del': order.status === 0}">
      <i class="ri-calendar-fill"></i>
      <div class="order__date">{{ moment.utc(order.routeDate).format('DD MMM') }}</div>
      <div class="order__status">
        <div
          class="indicator indicator--fill"
          :class="{
            'indicator--yellow': order.status === 0,
            'indicator--green': order.status === 1 || order.status === 2 || order.status === 7,
            'indicator--red': order.status === 3 || order.status === 4 || order.status === 8,
            'indicator--orange': order.status === 5 || order.status === 6
          }"
        >
          <span v-if="order.status === 0">Pending</span>
          <span v-if="order.status === 1">Approved</span>
          <span v-if="order.status === 2">Paid</span>
          <span v-if="order.status === 3">Declined</span>
          <span v-if="order.status === 4">Payment error</span>
          <span v-if="order.status === 6 || order.status === 5">Waiting for payment</span>
          <span v-if="order.status === 7">Created</span>
          <span v-if="order.status === 8">Canceled</span>
        </div>
      </div>
      <div v-if="order.status === 0" class="order__actions" @click.stop>
        <el-dropdown trigger="click" @command="action">
          <i class="ri-more-fill"></i>
          <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
            <el-dropdown-item class="item--remove" command="Cancel">
              <i class="ri-close-circle-fill"></i> Cancel
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="order__main">
      <div class="order__customer">
        <div class="icon-button icon-button--round icon-button--blue">
          <i class="ri-arrow-up-circle-line"></i>
        </div>
        <div class="order__customer-name">{{ $dots(order.pickUpName, amountCharset) }}</div>
        <div class="order__customer-address">{{ $dots(order.pickUpAddress, amountCharset) }}</div>
      </div>
      <div class="order__customer">
        <div class="icon-button icon-button--round icon-button--orange">
          <i class="ri-arrow-down-circle-line"></i>
        </div>
        <div class="order__customer-name">{{ $dots(order.dropOffName, amountCharset) }}</div>
        <div class="order__customer-address">{{ $dots(order.dropOffAddress, amountCharset) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {mapActions} from 'vuex'

export default {
  name: 'ClientOrder',
  props: {
    order: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      moment,
      amountCharset: 200
    }
  },
  created() {
    const fablet = document.documentElement.clientWidth <= 768
    if (fablet) this.amountCharset = 40
  },
  methods: {
    ...mapActions({
      cancel: 'orders/cancel'
    }),
    async action() {
      const resp = await this.cancel(this.order.id)
      if (resp.data.result) {
        this.order.status = 8
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
